<template>
    <div>
        <b-overlay :show="loadDimension" rounded="sm">

            <div class="container_site">
                
                <div class="container_dimension_mobile mb-3" :style="{'border-top': '10px solid '+dimension.color_dimension}">
                    <div class="container_dimension_mobile--title_dimension" v-if="!openMenuDimension">
                        <div style="width: 98%">{{dimension.name_dimension}}</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <MenuIcon />
                        </a>
                    </div>

                    <div class="container_dimension_mobile--title_dimension" v-if="openMenuDimension">
                        <div style="width: 100%" v-if="dimension.subcategory">Selecciona una secretaría</div>
                        <div style="width: 100%" v-else>Selecciona una dimensión</div>
                        <a 
                            href="#" 
                            class="container_dimension_mobile--icon_menu" 
                            @click="openMenuDimension = !openMenuDimension"
                        >
                            <ArrowUpIcon width="18.828" height="10.414" color="#000000" />
                        </a>
                    </div>

                    <DimensionMovileList v-if="openMenuDimension" :subcategory="dimension.subcategory"/>
                    
                </div>
                

                <div class="container_back_mobile">
                  <a @click="$router.go(-1)">
                      <img src="/img/icono-volver.bc8ecbba.svg" class="container_back_mobile__icon_back" > 
                      <p class="container_back_mobile__title"><span class="container_back_mobile__title--back">Volver a</span> {{Segment.name}}</p> 
                  </a>
                </div>

                <div class="container_dimension_mobile__card_segments--mobile">
                    <!-- <div class="container_dimension_mobile__card_segment--title_segment">
                        {{Category.name}} 
                    </div> -->
                    <div class="container_dimension_mobile__card_segment--title_segment" v-if="Category.id == 291">
                        {{Category.name}}
                         <a href="/pdf/PP ENVEJECIMIENTO HUMANO Y VEJEZ.pdf" download>
                            <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                        </a>
                    </div> 

                    <div class="container_dimension_mobile__card_segment--title_segment" v-else-if="Category.id == 289">
                        {{Category.name}}
                         <a href="/pdf/PP JUVENTUD.pdf" download>
                            <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                        </a>
                    </div> 

                    <div class="container_dimension_mobile__card_segment--title_segment" v-else-if="Category.id == 290">
                        {{Category.name}}
                        <a href="/pdf/PP EQUIDAD DE GÉNERO Y IGUALDAD DE OPORTUNIDAD PARA LAS MUJERES.pdf" download>
                            <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                        </a>
                    </div>

                    <div class="container_dimension_mobile__card_segment--title_segment" v-else-if="Category.id == 287">
                        {{Category.name}}
                        <a href="/pdf/PP PRIMERA INFANCIA Anexo Acuerdo No.012.pdf" download>
                            <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                        </a>
                    </div>

                    <div class="container_dimension_mobile__card_segment--title_segment" v-else-if="Category.id == 288">
                        {{Category.name}}
                        <a href="/pdf/PP ACCESO A VIVIENDA.pdf" download>
                            <img src="https://www.freeiconspng.com/uploads/pdf-icon-4.png" style="width: 35px;">
                        </a>
                    </div>

                    <div class="container_dimension_mobile__card_segment--title_segment" v-else>
                        {{Category.name}} 
                    </div>

                    <div class="container_dimension_mobile__card_segment--description_segment">
                        {{Category.description}}
                    </div>
                     <div class="container_dimension__content--content_graph">
                                    <div class="container_dimension__content--info_graph" v-for="(Image, keyImage) in Category.images" :key="keyImage" >
                                        <div class="container_dimension__content--title_graph" :style="{'padding-left': '15px'}">
                                            {{Image.title}}
                                        </div>
                                        <div class="container_dimension__content--description_graph">
                                            <img :src="Image.image.url" alt="" :style="{'width': '100%'}">
                                            
                                        </div>
                                        <div class="container_dimension__content--description_graph" :style="{'padding-left': '30px'}" >
                                            {{Image.description}}
                                        </div>
                                    </div>
                                </div>
                                <div class="container_dimension__content--content_graph">
                                    <div class="container_dimension__content--info_graph" v-for="(Video, keyVideo) in Category.videos" :key="keyVideo" >
                                        <div class="container_dimension__content--title_graph" :style="{'padding-left': '15px'}">
                                            {{Video.title}}
                                        </div>
                                        <div class="container_dimension__content--description_graph">
                                            <video :src="Video.video.url" :style="{'width': '100%'}" controls></video>
                                        </div>
                                        <div class="container_dimension__content--description_graph" :style="{'padding-left': '30px'}" >
                                            {{Video.description}}
                                        </div>
                                    </div>
                                </div>
                                <div class="container__pdfmobil">
                                    <div class="container__pdfmobil--info_pdf" v-for="(Pdf, keyPdf) in Category.pdfs" :key="keyPdf"  @click="goToPdf(Pdf.pdf.url)">
                                        <div class="container__pdfmobil--title_pdf">
                                            {{Pdf.title}}
                                        </div>
                                        <div class="container__pdfmobil--description_pdf">
                                            {{Pdf.description}}
                                        </div>
                                        <div class="container__pdfmobil--img_pdf">
                                            <img :src="require('../../assets/img/icono-pdf.svg')" alt="">
                                        </div>
                                        <div class="container__pdfmobil--button_pdf">
                                            <button>Ver documento</button>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                    <div class="container_graphs"  v-if="Segment.id != 223">
                        <div class="container_graphs--graph" v-for="(Graph, keyGraph) in Category.graphs.tables" :key="keyGraph" >
                            <div class="container_graphs--graph__title">
                                {{Graph.name}}
                            </div>
                            <div class="container_graphs--graph__description">
                                {{Graph.description}}
                            </div>
                            <div class="container_graphs--graph__type_graphic">
                                <img src="@/assets/img/grafica-pie.svg" v-if="Graph.type == 3">
                                <img src="@/assets/img/grafica-lineal.svg" v-if="Graph.type == 1">
                                <img src="@/assets/img/grafica-area-baja.svg" v-if="Graph.type == 5">
                                <img src="@/assets/img/grafica-columnas.svg" v-if="Graph.type == 2">
                                <img src="@/assets/img/grafica-barras-h.svg" v-if="Graph.type == 4">
                                <img src="@/assets/img/icono-cumplimiento.svg" v-if="Graph.type == 10">
                            </div>
                            <div class="container_graphs--graph__see_graphic" :style="cssStyle" @click="$router.push({name: 'Graph', query: {id: id, Category: Category.id, keyGraph: keyGraph, Type: 'Graph'}})">
                                <p>Ver gráfica</p>
                            </div>
                        </div>
                        <div class="container_graphs--graph" v-for="(Map, keyMap) in Category.graphs.maps" :key="keyMap">
                            <div class="container_graphs--graph__title">
                                {{Map.name}}
                            </div>
                            <div class="container_graphs--graph__description">
                                {{Map.description}}
                            </div>
                            <div class="container_graphs--graph__type_graphic">
                                <img src="@/assets/img/icono-mapa.svg">
                            </div>
                            <div class="container_graphs--graph__see_graphic" :style="cssStyle" @click="$router.push({name: 'Map', query: {url:Map.url, name:Map.name}})">
                                <p>Ver mapa</p>
                            </div>
                        </div>
                    </div>

                   <div v-else class="container_segments_table"  :class="{'expanded-block': Category.open}" style="overflow: auto !important;height: 300px !important;">
                      
                      <table v-if="Category.infoTable">
                  
                        <tr>
                          <th>ACCIONES DE LA POLÍTICA - Acuerdo 012 de 2019</th>
                          <!-- <th>PRODUCTO EN EL PDM 2020-2023</th> -->
                          <th>INDICADOR EN EL PDM 2020-2023</th>
                          <th>UNIDAD DE MEDIDA</th>
                          <th>META 2020</th>
                          <th>EJECUTADO 2020</th>
                          <th>META 2021</th>
                          <th>EJECUTADO 2021</th>
                          <th>INFO</th>
                       </tr>
                         <tr v-for="item in Category.infoTable" >
                           <td>{{ item[0]}}</td>
                           <!-- <td>{{ item[1]}}</td> -->
                           <td>{{ item[2]}}</td>
                           <td>{{ item[3]}}</td>
                           <td>{{ item[4]}}</td>
                           <td>{{ item[5]}}</td>
                           <td>{{ item[6]}}</td>
                           <td>{{ item[7]}}</td>
 
                           <td>
                            <button 
                            :style="{'background-color':dimension.color_dimension }" 
                            class="boton notranslate"
                            @click="goToGraphTableModalCategory(item,Segment.name)"
                            >i</button>
                          </td>
                         </tr>
                       </table>

                    </div>

                    <div class="container_dimension_mobile_products mb-3 container_dimension__content--content_products">
                        <div class="container_dimension__content--content_products--item" 
                            v-for="(Product, keyProduct) in Category.products" :key="keyProduct" @click="getProduct(Category.id, Product.id)">
                            <div class="container_dimension__content--content_products--item__title">
                                <p>{{Product.name}}</p>
                            </div>
                            <div class="container_dimension__content--content_products--item__button" :style="{'background-color': dimension.color_dimension}">
                                <button>Abrir producto<img :src="require('../../assets/img/icons-modal-products/abrir-producto.png')" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="container_btn_actions mb-5">
                    <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
                        <img src="@/assets/img/icono-regresar.svg" alt="">
                        <p>Regresar</p>
                    </div>
                    <div class="container_btn_actions__btn_home" @click="$router.push({name: 'Home'})">
                        <img src="@/assets/img/icono-inicio.svg" alt="">
                        <p>Inicio</p>
                    </div>
                </div>
                
            </div>
        </b-overlay>
       <Graph-Table-Modal-Category v-if="showGraphCategory==true"
        :product="product"
        :color="dimension.color_dimension"
        :segmentName="Category.name"
        @cancelProduct="showProductOut"
        />
    </div>
</template>
<script>
export default {
    data() {
        return {
            id:this.$route.params.id,
            dimension:[],
            segments: [],
            loadDimension: true,
            Category: null,
            Segment: [],
            products:[],
            openMenuDimension: false,
            segment_id: this.$route.query.Segment.data,
            product:[],
            showGraphCategory:false,
            segmentName: ''
        }
    },
    methods:{

        getProduct(Category, Product, id_dimension){
            this.$router.push({name: 'Product', params: {id_dimension: this.dimension.id }, query: {Product: Product,Segment_id: this.segment_id, Category_id: Category  }});
        },
        
        goToPdf(data){
            window.open(data, '_blank');
        },
        getDimension(){
            this.$http.get('dimensions/'+this.id).then(response =>{
                this.getSegments();
                this.dimension = response.body;
            }).catch(error => {
                //Console.Log('Error al consultar la dimensión: ', error)
            })
        },
        goToGraphTableModalCategory(product,name){
     
            this.product=product
            this.segmentName = name
            // this.modalCategory = category
            this.showGraphCategory=true
        },
        showProductOut(value) {
            this.showGraphCategory = value
        },
        getSegments(){
            this.$http.get('architecture_tags/dimension/'+this.id).then(response =>{
                this.Category = null;
                this.segments = response.body.Segments;
                this.loadDimension = false;
                response.body.Segments.forEach(Segment => {

                    if (Segment.id == this.$route.query.Segment) {
                        console.log('segment',Segment)
                        this.Segment = Segment
                        Segment.tags.categories.forEach(Category => {

                            if (Category.id == this.$route.query.Category) {
                                this.Category = Category;

                                
                                if(this.Category.graphs.tables.length > 0){

                                    console.log('aquiiiii')

                                      var formater = JSON.parse(this.Category.graphs.tables[0].data)

                                        this.Category.infoTable = formater

                                }
                                                        
                            }
                        });
                    }
                });


                console.log('category', this.Category)
                
            }).catch(error => {
                //Console.Log('Error al consultar los segmentos: ', error)
            })
        }
    },
    created() {
        this.getDimension();
        //console.log('volver', this.Segment_name)

    },
    
    computed: {
        cssStyle(){
            return {
                '--color-dimension' : this.dimension.color_dimension
            }
        }
    },
}
</script>

<style scoped>

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td{
  
  text-align: left;
  padding: 8px;
  font-size: 14px;
  height: 100px;
  text-align: center;
  border-right: 1px solid #ccc;
}

th{
  background-color: #383838;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: bold;
  height: 60px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #F4F4F4;
  
}

.container_dimension__content--content_products{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.container_dimension__content--content_products--item{
    height: auto;
    width: 100%;
    border-radius:5px;
    border: 1px solid #C6C6C6;
    margin-top: 15px;
} 
.container_dimension__content--content_products--item__title p{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin: 15px;
}
.container_dimension__content--content_products--item__button{
    height: 26px;
    width: 260px;
    border-radius: 5px;
    margin:0 15px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.container_dimension__content--content_products--item__button button{
    background: none;
    width: 100%;
    border: none;
    font-size: 12px;
    text-align: left;
    color: white;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.boton{
  width:30px;
  height:30px;
  margin: 5px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  font-size:19px;
  color: #FFFFFF;

}
.boton:hover{
  opacity: 0.50;
  -moz-opacity: .50;
  filter:alpha (opacity=50);
}
.boton a{
  color:#fff;
  text-decoration:none;
  padding:5px 5px 5px 0;
}

</style>